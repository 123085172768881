import { SessionUser } from 'next-auth'
import { isCurrentUserAdmin } from '../admin'
import { AuthIdentityProviderCodeEnum } from '@generated/types.d'

export const isRegistrationComplete = (user: SessionUser) => {
  // SSO users do not need MFA or Update password
  if (
    user?.identityProviderCode === AuthIdentityProviderCodeEnum.MicrosoftEntra
  )
    return true

  const userIsAdmin = isCurrentUserAdmin(user)
  if (userIsAdmin) return user?.isMfaEnabled

  return (
    user?.passwordLastChangeAt !== null &&
    (user?.isMfaEnabled || !!user?.mfaConfirmed)
  )
}
