import {
  AuthCurrentUserDocument,
  AuthCurrentUserQuery,
} from '@features/auth/operations/query/__generated__/auth-user'
import {
  AuthenticatedUserWithPositionsFragment,
  OnboardingProgressStepCodeEnum,
  User,
} from '@generated/types.d'
import { OperationVariables, QueryOptions } from '@palqee/apollo-client'
import {
  authLogout,
  currentLanguage,
  currentUser,
  generalCompanyOnboardingProgressList,
} from '@api'
import { redirectTo } from '../../../common/utils/helpers'

import { GetServerSidePropsContext } from 'next'
import { NEW_COMPANY_ID } from '@const/companies'
import { NextRouter } from 'next/router'
import { RecursivePartial } from 'index'
import { captureException } from '@sentry/nextjs'
import { generateMainStaticRoutes } from '@features/routing/lib/generate-routes'
import { initializeApollo } from '@palqee/apollo-client'
import { isRegistrationComplete } from './guards/registration'
import { isCurrentUserAdmin } from './admin'
import { clearLoginCookies } from './guards/cookies'

const handleLogoutWhenAdminSkipsMfa = async (
  ctx: GetServerSidePropsContext,
) => {
  const apolloClient = initializeApollo(ctx)

  await authLogout(apolloClient).catch(() => {
    // ignore
  })
  clearLoginCookies(ctx)
  await apolloClient.clearStore()
  await redirectTo(ctx, '/auth/login')
}

/**
 * @deprecated Remove use of this in favor of
 *              next-auth session or calling
 *              authcurrentuser client side
 *
 * @param ctx {PropsContext}
 * @param graphqlDocument {Document}
 * @param apolloClient
 * @param options
 * @param allowIncompleteEmployee
 * @returns {Partial<User>}
 */
export const getCurrentUserOrRedirect = async (
  ctx: GetServerSidePropsContext,
  graphqlDocument: any = AuthCurrentUserDocument,
  apolloClient?: any,
  options?: Partial<QueryOptions<OperationVariables>>,
  allowIncompleteEmployee = false,
) => {
  let client = apolloClient
  if (!apolloClient) {
    client = initializeApollo(ctx)
  }

  try {
    const currUserResponse = await currentUser(
      client,
      {
        companyId: String(ctx?.query?.companyId),
      },
      graphqlDocument,
      options || undefined,
    )

    const currentUserData = currUserResponse?.data?.auth?.userCurrent
      ?.user as User

    if (!currentUserData) throw new Error('User is unauthenticated')

    const defaultCompanyId =
      currentUserData?.defaultCompany?.id || currentUserData?.companies?.[0]?.id
    const companyId = ctx?.query?.companyId || defaultCompanyId

    const currentLanguageRes = await currentLanguage(client)
    const language = currentLanguageRes?.data?.auth?.userCurrent?.user?.language

    if (!isRegistrationComplete(currentUserData)) {
      if (isCurrentUserAdmin(currentUserData)) {
        await handleLogoutWhenAdminSkipsMfa(ctx)
      } else if (companyId) {
        if (!allowIncompleteEmployee) {
          await redirectTo(ctx, `/${companyId}/employees/register`, {
            language,
          })
        }
      }
    }

    return currentUserData
  } catch (err) {
    console.error(err)
    await client.resetStore()
    clearLoginCookies(ctx)
    await redirectTo(ctx, '/auth/login')
  }
}

// really dislike this method
// delete this shit and create better
// implementation / method
export const handleOnboardingLoginRedirect = async (
  ctx: GetServerSidePropsContext,
  completedStepCodes: OnboardingProgressStepCodeEnum[] = [],
  companyId: string = NEW_COMPANY_ID,
  router?: NextRouter,
  user?: RecursivePartial<User | AuthenticatedUserWithPositionsFragment>,
) => {
  // map completed steps
  const completedStepsMap:
    | Record<OnboardingProgressStepCodeEnum, boolean>
    | {} = {}

  completedStepCodes.forEach((step) => {
    completedStepsMap[step] = true
  })

  const dashboardUrl = user?.isAdmin
    ? generateMainStaticRoutes({
        companyId,
        page: 'surveys',
        subPage: 'manager',
      })
    : generateMainStaticRoutes({
        companyId,
        page: 'dashboard',
      })

  if (!user || user?.isAdmin) {
    // redirect to the step they haven't completed yet
    if (!completedStepCodes.length) {
      return ctx
        ? redirectTo(ctx, `/${companyId}/onboarding/`)
        : router.push(`/${companyId}/onboarding/`)
    }
    if (!completedStepsMap[OnboardingProgressStepCodeEnum.GeneralInfo]) {
      return ctx
        ? redirectTo(ctx, `/${companyId}/onboarding/general-info/`)
        : router.push(`/${companyId}/onboarding/general-info/`)
    }
    if (!completedStepsMap[OnboardingProgressStepCodeEnum.Dpo]) {
      return ctx
        ? redirectTo(ctx, `/${companyId}/onboarding/dpo/`)
        : router.push(`/${companyId}/onboarding/dpo/`)
    }
    if (!completedStepsMap[OnboardingProgressStepCodeEnum.Regions]) {
      return ctx
        ? redirectTo(ctx, `/${companyId}/onboarding/regions/`)
        : router.push(`/${companyId}/onboarding/regions/`)
    }
    if (!completedStepsMap[OnboardingProgressStepCodeEnum.ProcessOwners]) {
      return ctx
        ? redirectTo(ctx, `/${companyId}/onboarding/process-owners/`)
        : router.push(`/${companyId}/onboarding/process-owners/`)
    }
  }

  if (ctx) {
    return redirectTo(ctx, dashboardUrl)
  }

  return router.push(dashboardUrl)
}

const redirectToOnboardingIfIncomplete = async (
  ctx: GetServerSidePropsContext,
  companyId: string,
) => {
  try {
    const apolloClient = initializeApollo(ctx)
    if (companyId) {
      const onboardingRes = await generalCompanyOnboardingProgressList(
        apolloClient,
        {
          companyId,
        },
      )
      const onboardingProgress =
        onboardingRes?.data?.company?.onboardingStepsGet?.stepCodes || []
      handleOnboardingLoginRedirect(ctx, onboardingProgress, companyId)
    } else {
      handleOnboardingLoginRedirect(ctx, [], undefined)
    }
  } catch (err) {
    captureException(err)
  }
}

export const redirectCurrentLoggedInUser = async (
  ctx: GetServerSidePropsContext,
  currentLoggedInUser: RecursivePartial<User>,
) => {
  const userIsAdmin = isCurrentUserAdmin(currentLoggedInUser)

  if (!userIsAdmin) {
    const defaultCompanyId =
      currentLoggedInUser?.defaultCompany?.id ||
      currentLoggedInUser?.companies?.[0]?.id
    const companyId = ctx?.query?.companyId || defaultCompanyId
    if (companyId) {
      redirectTo(ctx, `/${companyId}/dashboard`)
    }
  } else {
    if (!isRegistrationComplete(currentLoggedInUser)) {
      await handleLogoutWhenAdminSkipsMfa(ctx)
      return
    }
    const defaultCompanyId =
      currentLoggedInUser?.defaultCompany?.id ||
      currentLoggedInUser?.companies?.[0]?.id
    const companyId = (ctx?.query?.companyId as string) || defaultCompanyId
    await redirectToOnboardingIfIncomplete(ctx, companyId)
  }
}

export const redirectIfLoggedIn = async (
  ctx: GetServerSidePropsContext,
  graphqlDocument: any = AuthCurrentUserDocument,
) => {
  const apolloClient = initializeApollo(ctx)
  try {
    const currUserResponse = await apolloClient.query<AuthCurrentUserQuery>({
      query: graphqlDocument,
      variables: {
        companyId: ctx?.query?.companyId,
      },
      fetchPolicy: 'network-only',
    })
    const currentLoggedInUser = currUserResponse?.data?.auth?.userCurrent?.user
    if (currentLoggedInUser) {
      await redirectCurrentLoggedInUser(ctx, currentLoggedInUser)
    } else {
      await apolloClient.cache.reset()
      await apolloClient.clearStore()
    }
  } catch (err) {
    // https://github.com/apollographql/apollo-client/issues/3766#issuecomment-619968051
    await apolloClient.cache.reset()
    await apolloClient.clearStore()
    clearLoginCookies(ctx)
    console.log(err)
    return ''
  }
}
