import { useCallback } from 'react'
import {
  type UsePostHogReturn,
  usePostHog as usePostHogParent,
} from '@palqee/analytics'
import { useRouter } from 'next/router'
import { useQuery } from '@apollo/client'
import { graphql } from '@generated/gql'

const CompanyNameGetDocument = graphql(/* GraphQL */ `
  query CompanyNameGet($input: CompanyGetInput!) {
    company {
      get(input: $input) {
        company {
          id
          name
        }
      }
    }
  }
`)

export const usePostHog = <T>(): UsePostHogReturn<T> => {
  const router = useRouter()

  const { data: companyNameGetData } = useQuery(CompanyNameGetDocument, {
    variables: {
      input: {
        companyId: String(router.query.companyId),
      },
    },
    skip: !router.query.companyId,
  })

  const [client, methods] = usePostHogParent<T>({
    groupName: 'company',
    groupId: String(router.query.companyId),
  })
  const { setPosthogGroup: setPosthogGroupParent, ...rest } = methods

  const setPosthogGroup = useCallback(
    (data = {}) => {
      if (!router.query.companyId) {
        setPosthogGroupParent({
          data: {
            name: companyNameGetData?.company?.get?.company?.name,
            ...data,
          },
        })
      }
    },
    [
      client,
      router.query.companyId,
      companyNameGetData?.company?.get?.company?.name,
      setPosthogGroupParent,
    ],
  )

  return [
    client,
    {
      ...rest,
      setPosthogGroup,
    },
  ]
}
