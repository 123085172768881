import { NextRouter } from 'next/router'
import { Session } from 'next-auth'
import { Company } from '@generated/types.d'
import { LoginFormInterface } from '../hooks/use-login/types'
import { ApolloClient } from '@apollo/client'

export enum LoginPayload {
  LoginSuccessPayload = 'AuthLoginSuccessPayload',
  LoginNextChallengePayload = 'AuthLoginNexChallengePayload',
}

export interface IAdminFlowProps {
  session: Session
  router: NextRouter
  resetLanguage?: boolean
  // eslint-disable-next-line @typescript-eslint/ban-types
  client: ApolloClient<object>
}

export interface LoginProps
  extends Pick<LoginFormInterface, 'identityApplication' | 'rememberMe'> {
  company: Company
}
