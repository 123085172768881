import mergeWith from 'lodash/mergeWith'
import { isObjectEmpty } from '@common/utils'

const mergeCustom = (objValue, srcValue) => {
  if (objValue === '' || objValue === null) {
    return srcValue
  }
}

export const mergeHandler = (currentSession, newSession) => {
  if (isObjectEmpty(newSession)) {
    return currentSession
  }

  return mergeWith(currentSession, newSession, mergeCustom)
}

export const updateSessionHandler = (state, action) => {
  const currentSession = state?.session
  const newSession = action?.payload?.session

  const d = mergeHandler(currentSession, newSession)

  return {
    ...state,
    session: d,
  }
}

export const reducer = (state, action) => {
  switch (action.type) {
    case 'update':
      return updateSessionHandler(state, action)
  }
}
