import { getCookie, setCookie } from 'cookies-next'
import { IncomingMessage, ServerResponse } from 'http'
import { CookieObj, CookieRequest, CookieResponse } from './types'

export const setPqCookie = (
  cookie: CookieObj,
  req?: CookieRequest,
  res?: CookieResponse,
) => {
  setCookie(cookie.key, cookie.value, {
    req: req as IncomingMessage,
    res: res as ServerResponse,
    path: '/',
    httpOnly: process.env.NODE_ENV === 'production',
    sameSite: 'lax',
    secure: process.env.NODE_ENV === 'production',
  })
}

export const getCookieValue = (
  cookieKey: string,
  req?: CookieRequest,
  res?: CookieResponse,
) => {
  const cookieValue = getCookie(cookieKey, {
    req: req as IncomingMessage,
    res: res as ServerResponse,
  })

  if (!cookieValue) return null

  // If the cookie value is a string, attempt to parse it
  if (typeof cookieValue === 'string') {
    try {
      // Attempt to parse the string as JSON
      const parsedValue = JSON.parse(cookieValue)

      // Check if the parsed value is an object or array
      if (parsedValue && typeof parsedValue === 'object') {
        return parsedValue
      } else {
        // If it's a primitive type after parsing, return the original string
        return cookieValue
      }
    } catch (error) {
      // If parsing fails, it's not a JSON string, so return the original string
      return cookieValue
    }
  }

  // If the cookie value is not a string, return it directly (assuming it's already an object)
  return cookieValue
}
