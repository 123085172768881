import { useEffect, useRef } from 'react'
import { usePostHog } from '@features/core/posthog/hooks'
import { buildUserDataAnalytics } from '@features/user/lib'
import { AuthenticatedUserWithPositionsFragment } from '@generated/types.d'
import { useRouter } from 'next/router'
import { sendGTMEvent } from '@next/third-parties/google'

export const useUserAnalytics = (props: {
  user: AuthenticatedUserWithPositionsFragment
  loading?: boolean
}) => {
  const router = useRouter()
  const { user, loading } = props

  const [, { setPosthogPeople }] = usePostHog()

  const { user: userDetails, company: companyInfo } =
    buildUserDataAnalytics<AuthenticatedUserWithPositionsFragment>(
      user,
      String(router.query.companyId),
    )

  // send to posthog
  useEffect(() => {
    setPosthogPeople(userDetails, 'set', {
      data: {
        userType: userDetails.userType,
      },
    })
  }, [userDetails, setPosthogPeople])

  // send event to gtm
  const loaded = useRef(false)
  useEffect(() => {
    // send user to data layer
    if (!loaded.current && !loading && user) {
      sendGTMEvent({
        event: 'user_loaded',
        value: {
          user: userDetails,
          company: companyInfo,
        },
      })
      loaded.current = true
    }

    return () => {
      loaded.current = false
    }
  }, [companyInfo, userDetails, loading, user])
}
