import { useCompanyFeaturesGetQuery } from '@generated/types.d'
import { useAuthCurrentUserQuery } from '@features/auth/operations/query/__generated__/auth-user'
import { useRouter } from 'next/router'
import { useSession } from '@features/auth'
import { validate as uuidValidate } from 'uuid'
import { useUserAnalytics } from '../user-user-analytics'

export const useUser = () => {
  const router = useRouter()
  const {
    query: { companyId },
  } = router

  const { session } = useSession({
    required: true,
  })

  // until RBAC is implemented we will query features and
  // set this in the global state context
  // @todo improve on our state management setup

  // only make request when accessToken is available
  // this is due to the mfa confirm page where your status
  // is authenticated but we do not yet have an accessToken
  const { data: authCurrentUserRes, loading } = useAuthCurrentUserQuery({
    variables: {
      companyId: String(companyId),
    },
    skip: session?.data?.accessToken === '' || !uuidValidate(String(companyId)),
  })

  // move this to use-company
  const { data: companyFeaturesGetRes } = useCompanyFeaturesGetQuery({
    variables: {
      companyId: String(companyId),
    },
    skip: session?.data?.accessToken === '' || !uuidValidate(String(companyId)),
  })

  const user = authCurrentUserRes?.auth?.userCurrent?.user ?? null
  const features =
    companyFeaturesGetRes?.company?.companyFeaturesGet?.features ?? null

  useUserAnalytics({
    user,
    loading,
  })

  return {
    user,
    loading,
    session,
    features,
  }
}
