import { AuthRoleCodeEnum, User, UserRole } from '@generated/types.d'
import { RecursivePartial } from 'index'

export const getCurrentRole = (
  roles: RecursivePartial<UserRole>[],
  companyId: string,
) => {
  const foundRole = roles?.find((r) => {
    if (r.companyId === companyId) {
      if (r.code === AuthRoleCodeEnum.Administrator) {
        return r.code
      } else if (r.code === AuthRoleCodeEnum.Employee) {
        return r.code
      }

      // return first one found in list
      return r.code
    }
    return r.code === AuthRoleCodeEnum.Administrator
  })

  if (foundRole) return foundRole.code
}

export const checkRoleByCompany = (
  roles: RecursivePartial<UserRole[]>,
  companyId: string,
  roleCode: AuthRoleCodeEnum,
) => {
  return roles?.some((r) => {
    return r.code === roleCode && r.companyId === companyId
  })
}

export const buildUser = (user) => {
  const { id, email, isMfaEnabled, language } = user

  const defaultCompanyId = user?.defaultCompanyId ?? user?.companies?.[0]?.id

  const isAdmin = checkRoleByCompany(
    user?.roles,
    defaultCompanyId,
    AuthRoleCodeEnum.Administrator,
  )

  const currentRole = getCurrentRole(user?.roles, defaultCompanyId)

  return {
    id,
    email,
    isMfaEnabled,
    language,
    isAdmin,
    defaultCompanyId,
    currentRole,
  }
}

export const hasRole = (
  roleCode: AuthRoleCodeEnum,
  roles: Partial<UserRole>[],
) => {
  return roles.some((role) => role.code === roleCode)
}

export const checkUserPermissions = (
  currentUser: RecursivePartial<User>,
  companyId?: string,
) => {
  const { roles } = currentUser ?? {}

  let isCompanyAdmin = false
  if (!!companyId) {
    isCompanyAdmin = checkRoleByCompany(
      roles,
      companyId,
      AuthRoleCodeEnum.Administrator,
    )
  } else {
    isCompanyAdmin = roles.some(
      (role) => role.code === AuthRoleCodeEnum.Administrator,
    )
  }

  // for now keep it simple
  // but in the future we will have
  // detailed permissions
  return {
    isCompanyAdmin,
  }
}

export const allowedAccesByRoles = (
  companyId: string,
  allowedRoles: AuthRoleCodeEnum[],
  userRoles:
    | UserRole[]
    | { code: AuthRoleCodeEnum; companyId: string | string[] }[],
) => {
  return userRoles.some((userRole) => {
    const checkHasAllowedRole = () =>
      allowedRoles.some((allowedRole) => allowedRole === userRole.code)

    if (!companyId) {
      return checkHasAllowedRole()
    }

    return userRole.companyId === companyId && checkHasAllowedRole()
  })
}
