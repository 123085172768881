import * as Types from '../../../../../__generated__/types.d'

import { gql } from '@apollo/client'
import { LoggedInUserFragmentDoc } from '../../__generated__/fragments'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type AuthGetLoggedUserQueryVariables = Types.Exact<{
  [key: string]: never
}>

export type AuthGetLoggedUserQuery = {
  __typename: 'Query'
  auth: {
    __typename: 'NamespaceAuthQuery'
    userCurrent: {
      __typename: 'AuthUserCurrentPayload'
      user?: {
        __typename: 'User'
        id: string
        firstName?: string | null
        lastName?: string | null
        email: any
        status: Types.UserStatusEnum
        isMfaEnabled?: boolean | null
        language?: Types.LanguageCodeEnum | null
        phoneNumber?: any | null
        passwordLastChangeAt?: any | null
        defaultCompanyId?: string | null
        roles: Array<{
          __typename: 'UserRole'
          userId: string
          roleId: string
          name: string
          uiCode: any
          companyId?: string | null
          code: Types.AuthRoleCodeEnum
        }>
      } | null
      identity?: {
        __typename: 'AuthUserIdentity'
        id: string
        identityApplication: {
          __typename: 'AuthIdentityApplication'
          id: string
          identityProviderCode: Types.AuthIdentityProviderCodeEnum
        }
      } | null
    }
  }
}

export const AuthGetLoggedUserDocument = gql`
  query AuthGetLoggedUser {
    auth {
      userCurrent {
        user {
          ...LoggedInUser
        }
        identity {
          id
          identityApplication {
            id
            identityProviderCode
          }
        }
      }
    }
  }
  ${LoggedInUserFragmentDoc}
`

/**
 * __useAuthGetLoggedUserQuery__
 *
 * To run a query within a React component, call `useAuthGetLoggedUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuthGetLoggedUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuthGetLoggedUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useAuthGetLoggedUserQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AuthGetLoggedUserQuery,
    AuthGetLoggedUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    AuthGetLoggedUserQuery,
    AuthGetLoggedUserQueryVariables
  >(AuthGetLoggedUserDocument, options)
}
export function useAuthGetLoggedUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AuthGetLoggedUserQuery,
    AuthGetLoggedUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    AuthGetLoggedUserQuery,
    AuthGetLoggedUserQueryVariables
  >(AuthGetLoggedUserDocument, options)
}
export function useAuthGetLoggedUserSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        AuthGetLoggedUserQuery,
        AuthGetLoggedUserQueryVariables
      >,
) {
  const options =
    baseOptions === Apollo.skipToken
      ? baseOptions
      : { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    AuthGetLoggedUserQuery,
    AuthGetLoggedUserQueryVariables
  >(AuthGetLoggedUserDocument, options)
}
export type AuthGetLoggedUserQueryHookResult = ReturnType<
  typeof useAuthGetLoggedUserQuery
>
export type AuthGetLoggedUserLazyQueryHookResult = ReturnType<
  typeof useAuthGetLoggedUserLazyQuery
>
export type AuthGetLoggedUserSuspenseQueryHookResult = ReturnType<
  typeof useAuthGetLoggedUserSuspenseQuery
>
export type AuthGetLoggedUserQueryResult = Apollo.QueryResult<
  AuthGetLoggedUserQuery,
  AuthGetLoggedUserQueryVariables
>
export const namedOperations = {
  Query: {
    AuthGetLoggedUser: 'AuthGetLoggedUser',
  },
}
