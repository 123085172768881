import { ISession, mergeHandler, reducer } from '@features/auth'
import {
  UseSessionOptions,
  useSession as useNextAuthSession,
} from 'next-auth/react'

import { makeVar } from '@apollo/client'
import { useReducer } from 'react'

// create initial value
const sessionInitialState: ISession = {
  user: {
    email: '',
    firstName: '',
    hasAdminRole: false,
    id: '',
    mfaType: '',
    defaultCompanyId: null,
    hasChangedPassword: false,
    isMfaEnabled: false,
    language: null,
    lastName: '',
    passwordLastChangeAt: null,
    phoneNumber: null,
  },
}

export const sessionStateVar = makeVar(sessionInitialState)

export const useSession = (props?: UseSessionOptions<boolean>) => {
  const { status, data } = useNextAuthSession(props)
  const [state, dispatch] = useReducer(reducer, {
    session: mergeHandler(sessionInitialState, data),
  })

  const updateSession = (session) => {
    dispatch({
      type: 'update',
      payload: {
        session,
      },
    })
  }

  return {
    session: {
      data: {
        ...state?.session,
      },
      status,
    },
    updateSession,
  }
}
