import { LoginPayload, useSession } from '@features/auth'
import { PqIntlActionTypes, usePqIntl } from '@palqee/intl'
import { useCallback, useEffect } from 'react'

import { LOGIN } from '@common/components/consts'
import { useRouter } from 'next/router'

export const useLanguageChecker = () => {
  const router = useRouter()
  const {
    session: { data: session, status },
  } = useSession()

  const [state, dispatchLocale] = usePqIntl()

  const language = router?.locale

  // only switch when user is properly authenticated
  const canUpdate =
    status === 'authenticated' &&
    session?.loginPayload === LoginPayload.LoginSuccessPayload

  const setClientLocaleFromServer = useCallback(() => {
    if (language !== state.locale && canUpdate) {
      dispatchLocale({
        type: PqIntlActionTypes.SET_LOCALE,
        payload: {
          locale: language,
        },
      })
    }
  }, [dispatchLocale, state.locale, language, canUpdate])

  const onRouteChangeComplete = useCallback(
    (url) => {
      if (!url.includes(LOGIN)) setClientLocaleFromServer()
    },
    [setClientLocaleFromServer],
  )

  useEffect(() => {
    router.events.on('routeChangeComplete', onRouteChangeComplete)
    return () => {
      router.events.off('routeChangeComplete', onRouteChangeComplete)
    }
  }, [router.events, onRouteChangeComplete])
}
