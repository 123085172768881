import { AuthRoleCodeEnum, UserRole } from '@generated/types.d'

import { ModuleTypeCode } from '@features/tasks/types'
import { checkRoleByCompany } from '@features/auth/lib/authorization'

export const setUserTypeFromRoles = (roles: UserRole[], companyId?: string) => {
  if (checkRoleByCompany(roles, companyId, AuthRoleCodeEnum.Administrator)) {
    return AuthRoleCodeEnum.Administrator
  } else if (checkRoleByCompany(roles, companyId, AuthRoleCodeEnum.Vendor)) {
    return AuthRoleCodeEnum.Vendor
  } else {
    return AuthRoleCodeEnum.Employee
  }
}

export const setTaskEventLinkedTo = (data) => {
  const linkedTo = []
  if (data?.linkedUrls?.length > 0) {
    linkedTo.push(ModuleTypeCode.URL)
  }

  if (data?.linkedSurveySessionIds?.length > 0) {
    linkedTo.push(ModuleTypeCode.SURVEY)
  }

  if (data?.linkedDsarRequests?.length > 0) {
    linkedTo.push(ModuleTypeCode.DSAR)
  }

  if (data?.linkedDocboxDocumentIds?.length > 0) {
    linkedTo.push(ModuleTypeCode.DOCUMENT)
  }

  return linkedTo
}
