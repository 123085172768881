import {
  AuthCurrentUserDocument,
  AuthCurrentUserQuery,
} from '@features/auth/operations/query/__generated__/auth-user'
import { AuthRoleCodeEnum, Industry, User, UserRole } from '@generated/types.d'
import { RecursivePartial } from 'index'
import { checkRoleByCompany } from '@features/auth'
import { sendGTMEvent } from '@next/third-parties/google'
import { setUserTypeFromRoles } from '@features/core/posthog/lib'
import { ApolloClient } from '@apollo/client'

export const parseFullNameToFirstLast = (name: string): string[] => {
  if (!name) return ['', '']

  const spaceIdx = name.indexOf(' ')

  let firstName = name
  let lastName = ''
  if (spaceIdx !== -1) {
    firstName = name.slice(0, spaceIdx)
    lastName = name.slice(spaceIdx + 1, name.length)
  }

  return [firstName, lastName]
}

export const buildUserDataAnalytics = <T extends RecursivePartial<User> = User>(
  user: T,
  companyId?: string,
) => {
  const role = user?.positions?.[0]?.position?.name
  const department = user?.positions?.[0]?.department?.name

  return {
    user: {
      email: user?.email,
      name: user?.fullName,
      firstName: user?.firstName,
      lastName: user?.lastName,
      userType: setUserTypeFromRoles(user?.roles as UserRole[], companyId),
      subscription: user?.tenant?.hasPermanentAccess ? 'Paid' : 'Trial',
      // https://palqee.atlassian.net/browse/PL-2467
      language: user?.language === 'pt' ? 'pt-br' : user?.language,
      isProcessOwner: checkRoleByCompany(
        user?.roles,
        companyId,
        AuthRoleCodeEnum.ProcessOwner,
      ),
      role,
      department,
    },
    company: {
      name: user?.defaultCompany?.name,
      industries: user?.defaultCompany?.industries.map(
        (industry: Industry) => industry?.name,
      ),
      country: user?.defaultCompany?.address?.country?.name,
      numberOfEmployees: user?.defaultCompany?.numberOfEmployees,
    },
  }
}

export const addUserToDatalayer = async (
  client: ApolloClient<object>,
  companyId: string,
) => {
  const result = await client.query<AuthCurrentUserQuery>({
    query: AuthCurrentUserDocument,

    variables: {
      companyId,
    },
  })
  const user = result?.data?.auth?.userCurrent?.user

  const parsedUser = buildUserDataAnalytics(user, companyId)

  sendGTMEvent({
    event: 'user_loaded',
    value: parsedUser,
  })
}
