/* istanbul ignore file */

import { AuthRoleCodeEnum, Company, User } from '@generated/types.d'

import { RecursivePartial } from 'index'

export const hasAdminAccess = (
  company: RecursivePartial<Company>,
  user: RecursivePartial<User>,
): boolean =>
  user?.roles.some(
    (role) =>
      role?.code === AuthRoleCodeEnum.Administrator &&
      role?.companyId === company?.id,
  )

export const isCurrentUserAdmin = (currentUser: any): boolean => {
  const userIsAdmin = currentUser?.roles?.find(
    (role) => role?.code === 'ADMINISTRATOR',
  )

  // 1. check if roles is being passed (this would always be
  //    false as we no longer store this in cookie)
  // 2. check hasAdminRole - this appears to be false at times
  //    believe this is because it's not using BE as source
  // 3. check isAdmin this is linked to BE and should always be
  //    consistent
  return !!userIsAdmin || currentUser?.hasAdminRole || currentUser?.isAdmin
}
